<template>
  <div v-if="!overlay">
    <div>
      <!-- COVER contiene il box introduttivo che può contenere un messaggio promo o i campi di ricerca dell'Homepage -->
      <div class="cover" style="margin-top: -30px">
        <div class="cover__filter"></div>
        <div class="cover__image"></div>
        <div class="cover__caption">
          <div class="cover__caption__copy">
            <h1>Cerca in tutto il catalogo GFL</h1>
            <div class="cover__caption__copy__searchbox" style="display: flex">
              <v-combobox
                class="comboMateriaHome"
                v-model="materiaSelected"
                :items="materie"
                light
                item-text="nome_interesse"
                :menu-props="propcombo"
                placeholder="Materia"
                dense
                rounded
                clearable
                filled></v-combobox>
              <v-text-field
                style="width: 49%; border-top-left-radius: 0; border-bottom-left-radius: 0; margin-left: 10px"
                v-on:keyup="keyPress"
                v-model="searchText"
                filled
                rounded
                append-icon="search"
                @click:append="research()"
                dense></v-text-field>
            </div>
            <a v-if="settings" :href="settings.marketing.contatta_agenti" target="_blank" class="cta orange" title="Richiedi maggiori informazioni"
              >Richiedi maggiori informazioni</a
            >
          </div>
        </div>
      </div>

      <div class="section bg-grey" v-if="catalogue">
        <div class="intro">
          <h2>Le novità</h2>
          <span class="border-lightBlue"></span>
          <p>
            In anteprima per te le novità editoriali del catalogo
            <strong>Giuffrè Francis Lefebvre</strong> con la possibilità di consultarne gratuitamente un estratto
          </p>
        </div>
        <v-container class="hidden-sm-and-down">
          <!-- GRID box per gestire la visualizzazione a griglia dei volumi -->
          <div class="grid clearfix">
            <div class="grid__item" v-for="item in catalogue" :key="item" @click="detail(item)">
              <a class="grid__item__caption">
                <div class="grid__item__caption__image">
                  <v-img v-if="item.provvedimento.campiCalcolati.cover_url" height="306" width="217" :src="getImage(item)"></v-img>

                  <v-img v-else width="217" height="306" :src="settings.configuration.placeholderIMG"></v-img>
                </div>
                <div class="grid__item__caption__text">
                  <h4>{{ item.provvedimento.titolo }}</h4>
                  <p>
                    <span v-if="item.provvedimento.collana">{{ item.provvedimento.collana.descrizione }}</span>
                    <br />
                    <span style="font-weight: bold; display: block" v-if="item.autori[0]">{{ item.autori[0].nome.substring(0, 1) }}. {{ item.autori[0].cognome }}</span>
                    <span style="font-weight: bold" v-if="item.autori[1]">{{ item.autori[1].nome.substring(0, 1) }}. {{ item.autori[1].cognome }}</span>
                  </p>
                </div>
              </a>
            </div>
            <div class="grid__actions" style="display: none">
              <a class="cta blue big" title="Carica altri risultati" @click="moreResults()">Più risultati</a>
            </div>
          </div>

          <!-- in versione smartphone invece del div GRID verrà mostrato il div con il carosello immagini -->
          <!--<div class="carousel">
              <div class="corousel__pagination-bullets"></div>
          </div>-->
        </v-container>

        <v-card elevation="24" max-width="444" class="mx-auto hidden-md-and-up" style="height: 306px; !important; width: 217px; !important">
          <v-carousel v-model="model" hide-delimiters @change="carousel(item)" class="carousel">
            <v-carousel-item v-for="item in catalogue" :key="item" @click="detail(item)" class="corousel__pagination-number">
              <v-img v-if="item.provvedimento.campiCalcolati.cover_url" height="306" width="217" :src="getImage(item)"></v-img>

              <v-img v-else width="217" height="306" :src="settings.configuration.placeholderIMG"></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
        <v-row justify="space-around" v-if="currentItem" style="margin-top: 20px" class="mx-auto hidden-md-and-up">
          <div class="grid__item__caption__text">
            <h4>{{ currentItem.provvedimento.titolo }}</h4>
            <p>
              <span v-if="currentItem.provvedimento.collana">{{ currentItem.provvedimento.collana.descrizione }}</span>
              <br />
              <span style="font-weight: bold" v-if="currentItem.autori[0]">{{ currentItem.autori[0].nome.substring(0, 1) }}. {{ currentItem.autori[0].cognome }}</span>
              <span style="font-weight: bold" v-if="currentItem.autori[1]">{{ currentItem.autori[1].nome.substring(0, 1) }}. {{ currentItem.autori[1].cognome }}</span>
            </p>
          </div>
        </v-row>
        <div class="grid__actions mx-auto hidden-md-and-up">
          <a class="cta blue big" title="Carica altri risultati" @click="moreResults()">Più risultati</a>
        </div>
      </div>
      <h1 v-else style="text-align: center; margin: 0 auto">
        <v-progress-circular style="margin-top: 20px" indeterminate color="primary"></v-progress-circular>
      </h1>
      <!-- I MIEI VOLUMI -->
      <div class="section bg-turquoise hidden-xs" style="margin-top: -10px">
        <div class="intro">
          <h2>I miei volumi</h2>
          <span class="border-lightBlue"></span>
          <p>
            <strong>I Miei Volumi</strong> è il servizio che ti permette di consultare in formato digitale i volumi che hai acquistato presso il tuo agente di fiducia o sul nostro
            shop.
            <br />
            <router-link to="/volumi">
              <b>Clicca qui per scoprire di più.</b>
            </router-link>
          </p>
        </div>
        <div class="banner clearfix">
          <div class="banner__copy">
            <div class="banner__copy__text bg_box-turquoise">
              <h2 style="display: none">Title box</h2>
              <p style="display: none">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas autem quam, explicabo pariatur sit nisi, adipisci saepe.</p>
            </div>
          </div>
          <div class="banner__image" v-if="catalogueVolumi.length > 0">
            <a class="banner__image__item" v-for="item in catalogueVolumi.slice(0, 6)" :key="item">
              <div class="banner__image__item__image" @click="detail(item)">
                <v-img v-if="item.provvedimento.campiCalcolati.cover_url" :src="getImage(item)" :title="item.provvedimento.campiCalcolati.estremo"></v-img>

                <v-img v-else src="images/placeolder_img-grid.svg" title="Immagine segnaposto volume"></v-img>
              </div>
            </a>
          </div>
        </div>
        <div class="banner banner--right clearfix">
          <div class="banner__image" v-if="catalogueVolumi.length > 0">
            <v-card elevation="24" max-width="444" class="mx-auto hidden-sm-and-up" style="height: 306px !important; width: 217px !important">
              <v-carousel v-model="model" hide-delimiters @change="carousel(item)" class="carousel">
                <v-carousel-item v-for="item in catalogueVolumi" :key="item" @click="detail(item)" class="corousel__pagination-number">
                  <v-img
                    v-if="item.provvedimento.campiCalcolati.cover_url"
                    :src="getImage(item)"
                    :title="item.provvedimento.campiCalcolati.estremo"
                    height="306"
                    width="217"></v-img>

                  <v-img v-else src="images/placeolder_img-grid.svg" title="Immagine segnaposto volume"></v-img>
                </v-carousel-item>
              </v-carousel>
            </v-card>

            <a class="banner__image__item" v-for="item in catalogueVolumi.slice(6, 12)" :key="item">
              <div class="banner__image__item__image" @click="detail(item)">
                <v-img v-if="item.provvedimento.campiCalcolati.cover_url" :src="getImage(item)" :title="item.provvedimento.campiCalcolati.estremo"></v-img>

                <v-img v-else src="images/placeolder_img-grid.svg" title="Immagine segnaposto volume"></v-img>
              </div>
            </a>
          </div>
          <div class="banner__copy">
            <div class="banner__copy__text bg_box-beige">
              <h2 style="display: none">Title box</h2>
              <p style="display: none">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas autem quam, explicabo pariatur sit nisi, adipisci saepe.</p>
            </div>
          </div>
        </div>
      </div>

      <!-- UNLIMITED -->
      <div class="section bg-heavenly">
        <div class="intro">
          <h2>Unlimited</h2>
          <span class="border-lightBlue"></span>
          <p>
            Scopri
            <strong>UNLIMITED</strong> e consulta oltre 3.000 volumi del catalogo Giuffrè Francis Lefebvre in formato digitale.
            <br />
            <router-link to="/unlimited">
              <b>Clicca qui per conoscere di più sul servizio.</b>
            </router-link>
          </p>
        </div>
        <div class="banner banner--center clearfix">
          <div class="banner__image">
            <v-card elevation="24" max-width="444" class="mx-auto hidden-sm-and-up" style="height: 306px; !important; width: 217px; !important">
              <v-carousel v-model="model" hide-delimiters @change="carousel(item)" class="carousel">
                <v-carousel-item v-for="item in catalogueUnlimited" :key="item" @click="detail(item)" class="corousel__pagination-number">
                  <v-img
                    v-if="item.provvedimento.campiCalcolati.cover_url"
                    :src="getImage(item)"
                    :title="item.provvedimento.campiCalcolati.estremo"
                    height="306"
                    width="217"></v-img>

                  <v-img v-else src="images/placeolder_img-grid.svg" title="Immagine segnaposto volume"></v-img>
                </v-carousel-item>
              </v-carousel>
            </v-card>

            <a class="banner__image__item" v-for="item in catalogueUnlimited" :key="item">
              <div class="banner__image__item__image" @click="detail(item)">
                <v-img v-if="item.provvedimento.campiCalcolati.cover_url" :src="getImage(item)" :title="item.provvedimento.campiCalcolati.estremo"></v-img>

                <v-img v-else src="images/placeolder_img-grid.svg" title="Immagine segnaposto volume"></v-img>
              </div>
            </a>
          </div>
        </div>
      </div>

      <!-- PROMO -->
      <div class="section bg-grey">
        <div class="promo">
          <div class="promo__caption">
            <div class="promo__caption__copy">
              <h3>COMINCIA SUBITO IL TUO PERIODO DI PROVA</h3>
              <a v-if="settings" :href="settings.marketing.contatta_agenti" target="_blank" class="cta orange" title="Richiedi maggiori informazioni"
                >Richiedi maggiori informazioni</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  .cover__caption__copy__searchbox div .v-input__slot {
    background: white !important;
  }
  .v-select-list {
    text-align: left;
  }
  .v-card {
    transition: opacity 0.3s ease-in-out;
  }
  .v-menu__content {
    box-shadow: none !important;
  }
  .v-card:not(.on-hover) {
    opacity: 0.8;
  }
  .v-text-field__slot input,
  .v-input__slot input {
    border: none;
  }
  .v-menu__content.theme--light.menuable__content__active.v-autocomplete__content {
    /*min-width: 350px !important;
  width: 350px !important;*/
  }
  i.v-icon.notranslate.mdi.mdi-menu-down.theme--light {
    cursor: pointer !important;
  }
</style>
<script>
  import NoContentViewVue from '../components/catalogue/NoContentView.vue'
  import axios from 'axios'
  import { mapState } from 'vuex'

  export default {
    props: {
      authorization: {
        type: Object
      },
      overlay: {
        type: Boolean
      },
      settings: {
        type: Object
      }
    },
    components: {
      NoContentView: NoContentViewVue
    },
    computed: {
      ...mapState(['config'])
    },
    data: (vm) => ({
      user: null,
      catalogue: null,
      materie: [],
      materiaSelected: null,
      searchText: null,
      currentItem: null,
      model: '',
      more: false,
      ifMateria: false,
      catalogueUnlimited: [],
      catalogueVolumi: [],
      propcombo: { maxHeight: '250', minWidth: '350' }
    }),
    async mounted() {
      //console.log("home");

      if (this.authorization.jwt) {
        this.user = this.authorization
      } else if (this.config.configuration) {
      }
      if (this.config.configuration) {
        var header = {
          Authorization: localStorage.token_biblio_vol_jwt
        }

        this.materiaSelected = this.config.materiaSelected ? this.config.materiaSelected : null
        if (localStorage.materie) {
          //controllo la data di scadenza per il refresh, settata a 20 min
          if (localStorage.dateSavedMaterie) {
            var now = Date.parse(new Date()) / 1000
            var exp = parseInt(localStorage.dateSavedMaterie) + parseInt(this.config.configuration.timeoutReloadMaterie)
            if (now >= exp) {
              await this.loadMaterie(header)
            } else {
              this.materie = JSON.parse(localStorage.materie)
            }
          } else {
            localStorage.materie = []
            await this.loadMaterie(header)
          }
        } else {
          await this.loadMaterie(header)
        }

        if (!this.$route.query.volumes) {
          this.REQUEST(this.config.configuration.apiURL + '/last/' + this.config.homepage.from + '/' + this.config.homepage.last, null, 'POST', 'list', null)

          await this.loadThumbsVolumes()

          await this.REQUEST(this.config.configuration.apiURL + '/last/' + 0 + '/' + 6 + '?unlimited=true', null, 'POST', 'listUnlimited', null)
        }
        let x = this.authorization
        this.$gtm.trackEvent({
          event: 'sezione',
          azione: 'open',
          descrizione: 'home page',
          proprieta: '',
          utente: this.authorization && this.authorization.profile && this.authorization.profile.userId ? this.authorization.profile.userId : null,
          crmid: this.authorization && this.authorization.profile && this.authorization.profile.crmId ? this.authorization.profile.crmId : null,
          stato: ''
        })
      }
    },
    /*watch: {
      'config.configuration'(newValue, oldValue) {
        if (this.config.configuration) {
          var header = {
            Authorization: localStorage.token_biblio_vol_jwt
          }

          this.materiaSelected = this.config.materiaSelected ? this.config.materiaSelected : null
          if (localStorage.materie) {
            //controllo la data di scadenza per il refresh, settata a 20 min
            if (localStorage.dateSavedMaterie) {
              var now = Date.parse(new Date()) / 1000
              var exp = parseInt(localStorage.dateSavedMaterie) + parseInt(this.config.configuration.timeoutReloadMaterie)
              if (now >= exp) {
                this.loadMaterie(header)
              } else {
                this.materie = JSON.parse(localStorage.materie)
              }
            } else {
              localStorage.materie = []
              this.loadMaterie(header)
            }
          } else {
            this.loadMaterie(header)
          }

          if (!this.$route.query.volumes) {
            this.REQUEST(this.config.configuration.apiURL + '/last/' + this.config.homepage.from + '/' + this.config.homepage.last, null, 'POST', 'list', null)

            this.loadThumbsVolumes()

            this.REQUEST(this.config.configuration.apiURL + '/last/' + 0 + '/' + 6 + '?unlimited=true', null, 'POST', 'listUnlimited', null)
          }
          let x = this.authorization
          this.$gtm.trackEvent({
            event: 'sezione',
            azione: 'open',
            descrizione: 'home page',
            proprieta: '',
            utente: this.authorization && this.authorization.profile && this.authorization.profile.userId ? this.authorization.profile.userId : null,
            crmid: this.authorization && this.authorization.profile && this.authorization.profile.crmId ? this.authorization.profile.crmId : null,
            stato: ''
          })
        }
      }
    },*/
    methods: {
      async REQUEST(call, header, method, type, body) {
        var self = this
        this.loading = true
        if (method == 'POST') {
          await axios
            .post(call, body, { headers: header })
            .then(function (response) {
              switch (type) {
                case 'list':
                  self.catalogue = response.data.list
                  self.currentItem = self.catalogue[0]
                  break
                case 'listUnlimited':
                  self.catalogueUnlimited = response.data.list
                  break
                case 'listVolumi':
                  self.catalogueVolumi = response.data.list
                  break
                case 'search':
                  self.settings.catalogo = {
                    search: response.data,
                    materiaSelected: self.materiaSelected,
                    materie: self.materie,
                    fromDetail: false,
                    fromUnlimited: false,
                    filters: {
                      fromDetail: false,
                      fromUnlimited: false,
                      fromVolumes: false
                    }
                  }

                  self.$router.push({
                    path: 'results',
                    query: { more: self.more, materia: self.ifMateria }
                  })

                  break
              }
            })
            .catch(function (error) {
              //localStorage.token_biblio_vol = null;
              localStorage.token_biblio_vol_jwt = self.settings.free.jwt
              self.token = self.settings.free.jwt
              //self.settings.freeToken = true;
            })
        } else {
          await axios
            .get(call, { headers: header })
            .then(function (response) {
              switch (type) {
                case 'materie':
                  self.materie = response.data.materie
                  self.materie.unshift({
                    nome_interesse: 'Tutto il catalogo'.toUpperCase()
                  })
                  localStorage.materie = JSON.stringify(self.materie)
                  localStorage.dateSavedMaterie = Date.parse(new Date()) / 1000
                  break
              }
            })
            .catch(function (error) {})
        }
      },
      setMateria() {
        //if(this.materiaSelected.nome_interesse != "TUTTO IL CATALOGO")
        //this.research();
      },
      createSessionTimestamp() {
        var d = new Date()
        var session = d.getFullYear().toString() + d.getMonth().toString() + d.getDate().toString() + d.getHours() + d.getMinutes() + d.getSeconds()
        return session
      },
      async loadMaterie(header) {
        await this.REQUEST(this.config.configuration.apiURL + '/materie', header, 'GET', 'materie', null)
      },
      moreResults() {
        this.config.catalogo = null
        this.$router.push({
          path: 'results',
          query: { more: true }
        })
      },
      carousel(item) {
        this.currentItem = this.catalogue[this.model]
      },
      keyPress(e) {
        if (e.keyCode === 13) {
          this.research()
        }
      },
      async loadThumbsVolumes() {
        var day = new Date().getDate()

        //OLD LOGIC
        /*
      var materia = this.materie[day] ? this.materie[day] : this.materie[30];
      if(materia){
      this.REQUEST(
        this.config.configuration.apiURL +
          "/last/" +
          0 +
          "/" +
          12 +
          "?materie=" + materia.interesse_id +"&unlimited=false",
        null,
        "POST",
        "listVolumi",
        null
      );
      }
      else{
        console.log("Errore materia: " + this.materie[day]);
        //
        this.REQUEST(
        this.config.configuration.apiURL +
          "/last/" +
          0 +
          "/" +
          12 +
          "?materie=" + '000000000000008F' +"&unlimited=false",
        null,
        "POST",
        "listVolumi",
        null
      );
      }*/
        let _from = 12 * day
        this.REQUEST(this.config.configuration.apiURL + '/last/' + _from + '/' + 12 + '?unlimited=false', null, 'POST', 'listVolumi', null)
      },
      research() {
        var from = 0
        var body = {}
        if ((this.searchText != null && this.searchText.length > 0) || (this.materiaSelected && this.materiaSelected.nome_interesse.toUpperCase() != 'TUTTO IL CATALOGO')) {
          if (this.materiaSelected && this.materiaSelected.nome_interesse.toUpperCase() != 'TUTTO IL CATALOGO') {
            body = {
              filters: [{ name: 'materia', value: [this.materiaSelected.interesse_id] }]
            }
          } else {
            body = {
              filters: []
            }
          }
          if (this.searchText) {
            this.more = false
            this.ifMateria = false
            body.text = this.searchText
            body.sort = { name: '_score', order: 'desc' }
          } else {
            this.ifMateria = true
            this.config.materiaSelected = this.materiaSelected
            this.more = true
            body.sort = { name: 'provvedimento.data', order: 'desc' }
          }
          var header = {
            Authorization: localStorage.token_biblio_vol_jwt
          }
          this.config.curentFilter = 'Tutto il Catalogo'
          this.REQUEST(this.config.configuration.apiURL + '/search/volumi/' + from + '/8', header, 'POST', 'search', body)
        }
      },
      detail(item) {
        this.$router.push({
          path: '/details',
          query: { cod_vol: item.provvedimento.codVol }
        })
      },
      getImage(item) {
        //BVA-93
        let prefix = this.config.copertina + '/pdf/'
        let uri = item.provvedimento.idDocMaster.toString().substring(item.provvedimento.idDocMaster.toString().length - 3, item.provvedimento.idDocMaster.toString().length)
        let fix = '/' + item.provvedimento.campiCalcolati.cover_url
        let final = prefix + uri + fix.replace('//', '/') + '?width=217&fit=outside'
        return final.replace(/([^:]\/)\/+/g, '$1')
      }
    }
  }
</script>
