<template>
  <div>
      <h6>Effettua il login per visualizzare il catalogo</h6>
  </div>
</template>
<script>
export default {
  data: vm => ({
    user: null
  }),
  mounted() {
    
  }
};
</script>