import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token_sso: null,
    config: {},
    jwt: null,
    profile: {}
  },
  mutations: {
    SET_CONFIG(state, data) {
      state.config = data
    },
    SET_AUTH(state, data) {
      state.jwt = data
    },
    SET_PROFILE(state, data) {
      state.profile = data
    }
  },
  actions: {
    setConfig({ commit }, payload) {
      commit('SET_CONFIG', payload)
    },
    setAuth({ commit }, payload) {
      commit('SET_AUTH', payload)
    },
    setProfile({ commit }, payload) {
      commit('SET_PROFILE', payload)
    },
    async API({ commit, state }, data) {
      const header = {
        Authorization: state.jwt
      }
      let res = null
      if (data.type == 'PUT') {
        res = await axios.put(data.call, data.body, { headers: header })
      }
      if (data.type == 'GET') {
        res = await axios.get(data.call, { headers: header })
      }
      return res
    },
    async putDownload({ commit, state }, payload) {
      //const uri = state.config.pdf_build.url.send_request + '/api/v1/queue/' + state.config.pdf_build.queue
      const uri = state.config.configuration.apiURL + '/utility/queue/' + state.config.pdf_build.queue
      const data = {
        //TODO
        source: state.config.pdf_build.source,
        crmid: payload.crmid,
        userid: payload.userid,
        watermark: 'Copyright Giuffrè Francis Lefebvre - ' + payload.userid + ' - RIPRODUZIONE RISERVATA',
        cod_vol: payload.codvol,
        filename: payload.filename
      }
      const body = {
        call: uri,
        body: data,
        type: 'PUT'
      }
      return await this.dispatch('API', body)
    }
  },
  getters: {
    downloadRole(state) {
      if (state.profile.roles) {
        return state.profile.roles.find((x) => x.pos == 'download_pdf_unico') ? true : false
      }
      return false
    }
  }
})
