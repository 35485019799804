import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
var qs = require('qs');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Biblioteca Volumi',
      metaTags: [
        {
          name: 'description',
          content: 'The home page'
        }
      ]
    }
  },
  {
    path: '/volumi',
    name: "Volumi",
    component: () => import('../views/Volumes.vue'),
    meta: {
      title: 'I miei volumi',
      metaTags: [
        {
          name: 'description',
          content: 'My volumes'
        }
      ]
    }
  },
  {
    path: '/catalogo',
    name: 'catalogo',
    component: () => import('../views/Catalogue.vue'),
    meta: {
      title: 'Catalogo',
      metaTags: [
        {
          name: 'description',
          content: 'Catalogue'
        }
      ]
    }
  },
  {
    path: '/unlimited',
    name: "Unlimited",
    component: () => import('../views/Unlimited.vue'),
    meta: {
      title: 'Unlimited',
      metaTags: [
        {
          name: 'description',
          content: 'Unlimited'
        }
      ]
    }
  },
  {
    path: '/results',
    name: 'results',
    component: () => import('../views/Results.vue'),
    meta: {
      title: 'Risultati ricerca',
      metaTags: [
        {
          name: 'description',
          content: 'Results'
        }
      ]
    }
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('../views/Details.vue'),
    meta: {
      reload: true,
      title: 'Dettaglio volume',
      metaTags: [
        {
          name: 'description',
          content: 'Details'
        }
      ]
    }
  },
  {
    path: '/cookie',
    component: () => import('../views/Cookie.vue'),
    meta: {
      title: 'Cookie policy',
      metaTags: [
        {
          name: 'description',
          content: 'Cookie'
        }
      ]
    }
  },
  {
    path: '*',
    component: Home
  }
];

const router = new VueRouter({
  stringifyQuery  : query => {
    let result = qs.stringify(query, { encode: false })
    return result ? ('?' + result) : ''
  },
  routes
});

export default router;
