import Vue from 'vue'
import 'vuetify/dist/vuetify.css'
import Vuetify from 'vuetify'
import App from './App.vue'
import VueHead from 'vue-head'
import router from './router'
import axios from 'axios'
import store from './store'
import VueRouter from 'vue-router'
import '@mdi/font/css/materialdesignicons.css' // npm i @mdi/font
import VueMeta from 'vue-meta'
import configuration from './configuration_variables.json'
import VueGtm from 'vue-gtm'

Vue.config.productionTip = false

// Metadati per ogni rotta
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag))

  if (sessionStorage.bv_siteParameters) {
    if (to.query.site) {
      next()
    } else {
      to.query.site = sessionStorage.bv_siteParameters
      next({ path: to.path, query: to.query })
    }
  } else {
    next()
  }
})

stabilish()

async function stabilish() {
  var url_sso = ''
  var url_gtm = ''
  var url_hf = ''
  var url_ff = ''
  const vuetifyOptions = {}
  Vue.use(VueHead)
  Vue.use(Vuetify)
  Vue.config.productionTip = false
  Vue.config.silent = true
  Vue.use(VueMeta)

  var url = location.href //BVA-59
  let siteParam = url.split('site=')
  if (siteParam[1]) {
    siteParam = siteParam[1].split('&')[0]
    sessionStorage.bv_siteParameters = siteParam
  } else if (sessionStorage.bv_siteParameters) {
    siteParam = sessionStorage.bv_siteParameters
  } else {
    siteParam = 'bibliotecavolumi'
  }

  axios
    .post(configuration.apiURL + '/utility/config', null, { headers: null })
    .then(async (response) => {
      url_sso = response.data.sso
      url_gtm = response.data.analitycs.id
      url_hf = response.data.header.url
      url_ff = response.data.footer.url
      //
      let settings = response.data
      settings.configuration = configuration
      await store.dispatch('setConfig', settings).then((res) => {
        console.log("config loaded")
      })
      //
      var cookie = false
      if (response.data.cookielaw && response.data.cookielaw.id) {
        console.log('dev cookielaw')
        cookie = true
      } else {
        cookie = false
      }
      Vue.use(VueGtm, {
        id: url_gtm, // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
        defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: true, // Whether or not display console logs debugs (optional)
        loadScript: true // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      })
      //vueRouter: router // Pass the router instance to automatically sync with router (optional)
      new Vue({
        router,
        store,
        vuetify: new Vuetify(vuetifyOptions),
        head: {
          link: [
            {
              rel: 'stylesheet',
              href: 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Material+Icons|Quattrocento+Sans:400,400i,700,700i&amp;display=swap'
            },
            {
              rel: 'stylesheet',
              href: '/css/style.css'
            }
          ],
          script: [
            /*{ type: "text/javascript", src: url_sso, async: true, body: true },*/
            cookie
              ? {
                  type: 'text/javascript',
                  src: `https://cdn.cookielaw.org/consent/${response.data.cookielaw.id.toString()}/OtAutoBlock.js`,
                  async: true,
                  body: true
                }
              : '',
            cookie
              ? {
                  type: 'text/javascript',
                  src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
                  async: true,
                  body: true,
                  'data-domain-script': response.data.cookielaw.id.toString()
                }
              : '',
            cookie
              ? {
                  type: 'text/javascript',
                  inner: 'function OptanonWrapper() { }',
                  async: true,
                  body: true
                }
              : '',
            {
              type: 'text/javascript',
              src: url_hf,
              id: 'gfl-hf-script',
              'hf-name': siteParam,
              'site-name': siteParam,
              'hf-ticket': '',
              async: true,
              body: true
            },
            {
              type: 'text/javascript',
              src: url_ff,
              id: 'gfl-ff-script',
              'ff-name': siteParam,
              'site-name': siteParam,
              async: true,
              body: true
            }
          ]
        },
        render: (h) => h(App)
      }).$mount('#app')
    })
    .catch(function (error) {
      console.log(error)
      url_sso = 'http://staging.provisioning.giuffre.it/logingfl/Scripts/LoginFunctionGFL.js'
      console.log('error configuration call')

      new Vue({
        router,
        store,
        vuetify: new Vuetify(vuetifyOptions),
        head: {
          link: [
            {
              rel: 'stylesheet',
              href: 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Material+Icons|Quattrocento+Sans:400,400i,700,700i&amp;display=swap'
            },
            {
              rel: 'stylesheet',
              href: '/css/style.css'
            }
          ]
          //script: [{ type: "text/javascript", src: url_sso, async: true, body: true }],
        },
        render: (h) => h(App)
      }).$mount('#app')
    })
}
